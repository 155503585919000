import { ReactElement, useEffect, useMemo } from 'react';

import { LabelPosition, SelectField, TextField } from '@pushpay/forms';
import { Stack, Tiles } from '@pushpay/layout';
import { Link } from '@pushpay/link';
import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { VimeoIdentifierType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';
import { getFeedInitialData } from '@src/pages/containerSettings/utils/containerSettingFormInitialDataGenerator';

import { useFeedAttributeDirtyContext } from '../feedAttributeDirtyContext';
import { ContainerFeed, VimeoFeedInputFields } from '../types';
import { FeedCommonFields, FeedCommonFieldsProps } from './FeedCommonFields';

export type VimeoFeedProps = ComponentProps<
	{
		feed: ContainerFeed;
		vimeoFields: VimeoFeedInputFields;
	} & Omit<FeedCommonFieldsProps, 'urlField' | 'propertiesField' | 'feedProcessState'>,
	typeof undefined
>;

const useStyle = createUseStyles((theme: Theme) => ({
	vimeoFeedTypeWrapper: {
		position: 'relative',
	},
	learnMore: {
		position: 'absolute',
		top: multiply(theme.SPACING.XSMALL, 1.5),
		right: 0,
	},
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

export const VimeoFeedFields = ({ vimeoFields, processFeed, enableDownloadButton, feed }: VimeoFeedProps) => {
	const { translate } = useTranslation('appDesign');
	const classes = useStyle(undefined);
	const { setDirty } = useFeedAttributeDirtyContext();

	const { vimeoFeed: originalValue } = getFeedInitialData(feed);
	const isDirty =
		originalValue &&
		(vimeoFields.channelId.value !== originalValue.channelId ||
			vimeoFields.groupId.value !== originalValue.groupId ||
			vimeoFields.identifier.value !== originalValue.identifier ||
			vimeoFields.properties.value !== originalValue.properties ||
			vimeoFields.showcaseId.value !== originalValue.showcaseId ||
			vimeoFields.userId.value !== originalValue.userId);

	useEffect(() => {
		setDirty(!!isDirty);
	}, [isDirty, setDirty]);

	const feedTypeOptions = useMemo(
		() => [
			{
				display: translate('attribute.vimeo.typeOptions.showcase'),
				value: VimeoIdentifierType.Showcase,
			},
			{
				display: translate('attribute.vimeo.typeOptions.channel'),
				value: VimeoIdentifierType.Channel,
			},
			{
				display: translate('attribute.vimeo.typeOptions.group'),
				value: VimeoIdentifierType.Group,
			},
			{
				display: translate('attribute.vimeo.typeOptions.userVideos'),
				value: VimeoIdentifierType.UserNotes,
			},
		],
		[translate]
	);

	const getInputFields = () => {
		const commonProps = {
			showLabel: true,
			labelPosition: 'top' as LabelPosition,
		};
		const inputFieldsMapping = new Map<VimeoIdentifierType, ReactElement>([
			[
				VimeoIdentifierType.Channel,
				<TextField
					classes={{ label: classes.label }}
					field={vimeoFields.channelId}
					label={translate('attribute.vimeo.fieldLabels.channelId')}
					{...commonProps}
				/>,
			],
			[
				VimeoIdentifierType.Showcase,
				<>
					<TextField
						classes={{ label: classes.label }}
						field={vimeoFields.userId}
						label={translate('attribute.vimeo.fieldLabels.userId')}
						{...commonProps}
					/>
					<TextField
						classes={{ label: classes.label }}
						field={vimeoFields.showcaseId}
						label={translate('attribute.vimeo.fieldLabels.showcaseId')}
						{...commonProps}
					/>
				</>,
			],
			[
				VimeoIdentifierType.Group,
				<TextField
					classes={{ label: classes.label }}
					field={vimeoFields.groupId}
					label={translate('attribute.vimeo.fieldLabels.groupId')}
					{...commonProps}
				/>,
			],
			[
				VimeoIdentifierType.UserNotes,
				<TextField
					classes={{ label: classes.label }}
					field={vimeoFields.userId}
					label={translate('attribute.vimeo.fieldLabels.userId')}
					{...commonProps}
				/>,
			],
		]);

		return vimeoFields.identifier.value && inputFieldsMapping.get(vimeoFields.identifier.value);
	};

	return (
		<>
			<Stack>
				<Tiles columns={2}>
					<div className={classes.vimeoFeedTypeWrapper}>
						<SelectField<VimeoIdentifierType | null>
							classes={{
								label: classes.label,
							}}
							data-pp-at-target="vimeo-feed-type"
							field={vimeoFields.identifier}
							label={translate('attribute.vimeo.label')}
							labelPosition="top"
							options={feedTypeOptions}
							showLabel
						/>
						<Tooltip
							classes={{ root: classes.learnMore }}
							content={translate('attribute.vimeo.description')}
							placement="top"
						>
							<Link
								href="https://care.pushpay.com/s/knowledge/Video-Adding-a-Vimeo-Album-or-Channel-Feed"
								target="_blank"
							>
								{translate('attribute.vimeo.learnMore')}
							</Link>
						</Tooltip>
					</div>
					{getInputFields()}
				</Tiles>
			</Stack>
			<FeedCommonFields
				enableDownloadButton={!isDirty && enableDownloadButton}
				feedProcessState={feed?.processState}
				processFeed={processFeed}
				propertiesField={vimeoFields.properties}
			/>
		</>
	);
};
