import { createContext, ReactNode, useContext, useState } from 'react';

const FeedAttributeDirtyContext = createContext<{ dirty: boolean; setDirty: (value: boolean) => void } | undefined>(
	undefined
);

export function FeedAttributeDirtyContextProvider({ children }: { children?: ReactNode }) {
	const [isDirty, setIsDirty] = useState(false);

	return (
		<FeedAttributeDirtyContext.Provider value={{ dirty: isDirty, setDirty: setIsDirty }}>
			{children}
		</FeedAttributeDirtyContext.Provider>
	);
}

export function useFeedAttributeDirtyContext() {
	const context = useContext(FeedAttributeDirtyContext);
	if (!context) {
		throw new Error('useFeedAttributeDirtyContext must be used within a FeedAttributeDirtyContextProvider');
	}
	return context;
}
