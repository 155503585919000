import { FC, MouseEvent, useRef, useState } from 'react';

import moment, { Moment } from 'moment';

import Flyout from '@pushpay/flyout';
import { Calendar, CalendarProps } from '@pushpay/inputs';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { useOnClickOutside } from '@pushpay/utils';

import { DraggableIcon } from '@src/components/draggable';

import { InputField } from './InputField';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexGrow: 1,
	},
	input: {
		flexGrow: 1,
	},
	time: {
		padding: `0 ${theme.SPACING.FIELD_PADDING_H}`,
		marginLeft: theme.SPACING.XXSMALL,
		backgroundColor: theme.colors['background-surface'],
		border: `${theme.SPACING.FIELD_BORDER_WIDTH} solid ${theme.colors['border-input']}`,
		borderRadius: theme.SHAPE.ROUNDED_CORNERS,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	timeReadOnly: {
		backgroundColor: theme.colors['background-input-readonly'],
		borderColor: theme.colors['border-input-disabled'],
	},
	icon: {
		fontSize: 16,
	},
}));

export type DateInputFieldProps = Omit<CalendarProps, 'value' | 'minDate' | 'maxDate' | 'onChange'> & {
	value?: Moment;
	format?: string;
	onChange?: (value?: Moment) => void;
	disableClear?: boolean;
};

const noOp = () => {};
export const DateInputField: FC<DateInputFieldProps> = ({
	id,
	value,
	format = 'dddd, MMMM D, YYYY',
	onChange = noOp,
	disableClear,
	...rest
}) => {
	const classes = useStyles(undefined);
	const [isOpen, setIsOpen] = useState(false);

	const containerRef = useRef<HTMLDivElement>(null);
	useOnClickOutside([containerRef], () => setIsOpen(false), isOpen);

	const onDateChange = (date?: Date) => {
		setIsOpen(false);

		const newDate = date ? moment(date) : undefined;
		onChange(newDate);
	};

	const onIconPress = (event: MouseEvent<HTMLDivElement>) => {
		if (!disableClear && value !== undefined) {
			event.preventDefault();
			event.stopPropagation();

			onChange(undefined);
			setIsOpen(false);
			return;
		}

		setIsOpen(!isOpen);
	};

	const getIconAfter = (): DraggableIcon => {
		if (disableClear) {
			return 'calendar-1';
		}

		return value !== undefined ? 'close' : 'calendar-1';
	};

	return (
		<div ref={containerRef} className={classes.root}>
			<InputField
				classes={{ root: classes.input }}
				iconAfter={getIconAfter()}
				id={`input-${id}`}
				value={value?.format(format) || ''}
				onClick={() => setIsOpen(!isOpen)}
				onIconAfterPress={onIconPress}
			/>

			<Flyout isOpen={isOpen} lockFocus={false} preferredFlyoutAlignment="auto" preferredFlyoutDirection="bottom">
				<Calendar
					firstDayOfWeek="sunday"
					id={`calendar-${id}`}
					maxDate={moment().add(10, 'years').toDate()}
					minDate={moment().subtract(10, 'years').toDate()}
					{...rest}
					value={value ? value.toDate() : undefined}
					onChange={onDateChange}
				/>
			</Flyout>
		</div>
	);
};
