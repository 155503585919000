import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
	banner: {
		display: 'flex',
		margin: `${theme.SPACING.SMALL} 0`,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
	},
	nameContainer: {
		overflow: 'hidden',
	},
	name: {
		font: theme.typography['heading-1'],
		color: theme.colors['text-heading'],
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		flexShrink: 7,
	},
	hiddenIcon: {
		width: theme.SPACING.MEDIUM,
		height: theme.SPACING.MEDIUM,
		color: theme.colors['grey-600'],
		margin: theme.SPACING.SMALL,
	},
	kebabContainer: {
		margin: theme.SPACING.XSMALL,
		borderRadius: theme.SHAPE.CIRCULAR,
	},
	kebab: {
		height: '36px',
		width: '36px',
		'&:hover': {
			backgroundColor: theme.colors['grey-300'],
		},
	},
	sticky: {
		backgroundColor: theme.colors['background-page'],
		margin: `0 -${theme.SPACING.SMALL}`,
		padding: `${theme.SPACING.MEDIUM} ${theme.SPACING.SMALL}`,
		position: 'sticky',
		top: 0,
		zIndex: 2,
	},
	containerName: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontSize: '32px',
		color: theme.colors['text-heading'],
		'&:hover': {
			color: theme.colors['productBrand-500'],
		},
	},
	link: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		paddingRight: theme.SPACING.XSMALL,
	},
	tooltipRoot: {
		display: 'flex',
	},
	editIcon: {
		width: theme.SPACING.LARGE,
		height: theme.SPACING.LARGE,
	},
}));
