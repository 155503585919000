import { useEffect } from 'react';

import { ComponentProps } from '@pushpay/types';

import { getFeedInitialData } from '@src/pages/containerSettings/utils/containerSettingFormInitialDataGenerator';

import { useFeedAttributeDirtyContext } from '../feedAttributeDirtyContext';
import { ContainerFeed, ICalFeedInputFields } from '../types';
import { FeedCommonFields, FeedCommonFieldsProps } from './FeedCommonFields';

export type CalendarFeedFieldsProps = ComponentProps<
	{
		iCalFeedFields: ICalFeedInputFields;
		feed: ContainerFeed;
		processFeed?: () => void;
	} & Omit<FeedCommonFieldsProps, 'urlField' | 'propertiesField' | 'feedProcessState'>,
	typeof undefined
>;

export const CalendarFeedFields = ({
	iCalFeedFields,
	processFeed,
	enableDownloadButton,
	feed,
}: CalendarFeedFieldsProps) => {
	const { icalFeed: originalValue } = getFeedInitialData(feed);
	const { setDirty } = useFeedAttributeDirtyContext();
	const isDirty =
		originalValue &&
		(iCalFeedFields.url.value !== originalValue.url ||
			iCalFeedFields.properties.value !== originalValue.properties);

	useEffect(() => {
		setDirty(!!isDirty);
	}, [isDirty, setDirty]);

	return (
		<FeedCommonFields
			enableDownloadButton={!isDirty && enableDownloadButton}
			feedProcessState={feed?.processState}
			processFeed={processFeed}
			propertiesField={iCalFeedFields.properties}
			urlField={iCalFeedFields.url}
		/>
	);
};
