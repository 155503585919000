import { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { MarkedDates } from 'react-native-calendars/src/types';

import { EventSection } from '@pushpay/app-components/dist/app/containers/EventsContainer';
import {
	formatEvents,
	formatEventsForCalendarView,
	getCalendarMaxDate,
	getCalendarMinDate,
	getValidatedDates,
} from '@pushpay/app-components/dist/app/helpers/events';
import EventsScreen from '@pushpay/app-components/dist/app/screens/Events/EventsScreen';
import NoEventsScreen from '@pushpay/app-components/dist/app/screens/Events/NoEventsScreen';
import { Event } from '@pushpay/app-components/dist/app/types/event';
import moment from '@pushpay/app-components/node_modules/moment';

import { useAppPreviewContext, useGlobalContext } from '@src/context';
import {
	ChildContainerFragment,
	ChildContainerWithChildrenFragment,
	ChildItemFragment,
	useGetContainerChildrenWithNestedChildrenQuery,
} from '@src/graphql/generated';
import { PREVIEW_CONTENT_HEIGHT } from '@src/pages/appDesign/components/PreviewScaleContainer';
import { CONTAINER_CHILDREN_PAGING_SIZE } from '@src/pages/constants';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { getImpliedEndDate } from '@src/shared/validation';
import { ISO_DATE_FORMAT } from '@src/utils';

import { ContainerPreviewData } from '../types';
import { getImageMap, noop } from '../utils';

export function EventList({ container }: { container: ContainerPreviewData }) {
	const { appSettings, screenWidth, screenHeight } = useAppPreviewContext();
	const { campus: currentCampusId } = useGlobalContext();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { data: containerWithNestedChildren } = useGetContainerChildrenWithNestedChildrenQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId: container.id,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-and-network',
	});
	const containerData = containerWithNestedChildren?.organization?.application?.container;

	const calendarDates = useRef({ startDate: getCalendarMinDate(), endDate: getCalendarMaxDate() });

	const [eventMap, setEventMap] = useState<Map<string, Event[]>>(new Map());
	const [markedDates, setMarkedDates] = useState<MarkedDates>({});
	const [eventSectionList, setEventSectionList] = useState<EventSection[]>([]);
	const [highlightedDate, setHighlightedDate] = useState('');
	const [initialDate, setInitialDate] = useState(''); // This is introduced to set the initial month when the preview loads and to ensure the today button functions correctly.

	const calculateMarkedDates = useCallback((fetchedEventsList: EventSection[]) => {
		const markedEventDates: MarkedDates = {};

		fetchedEventsList.forEach((section: EventSection) => {
			if (section.data.length > 0) {
				markedEventDates[section.title] = { marked: true };
			}
		});
		setMarkedDates(markedEventDates);
	}, []);

	const setCalendarViewEvents = useCallback(() => {
		const currentDate = moment();
		const startDate = moment(currentDate).startOf('month');
		const endDate = moment(currentDate).add(1, 'year').endOf('month');

		const { validatedStartDate, validatedEndDate } = getValidatedDates(
			{ startDate, endDate },
			calendarDates.current
		);

		const formattedList = formatEventsForCalendarView(eventMap, {
			startDate: validatedStartDate,
			endDate: validatedEndDate,
		});
		calculateMarkedDates(formattedList);
		setEventSectionList(formattedList);
	}, [calculateMarkedDates, eventMap]);

	const onMonthChange = useCallback(
		(date: string) => {
			if (!moment(date).isSame(highlightedDate, 'month')) {
				// This function addresses an issue in capps-react-app where onDateChange does not trigger when the user changes the month (specific to the app preview environment).
				const startOfMonthDate = moment(date).startOf('month').format(ISO_DATE_FORMAT);
				setHighlightedDate(startOfMonthDate);
			}
		},
		[highlightedDate]
	);

	useEffect(() => {
		if (containerData) {
			const eventMapList = getEventMap(
				{
					...container,
					children: containerData.children.nodes,
				},
				currentCampusId
			);
			setEventMap(eventMapList);

			const firstEventDate = eventMapList.keys().next().value;
			if (!firstEventDate) {
				return;
			}
			setHighlightedDate(firstEventDate);
		}
	}, [container, containerData, currentCampusId]);

	useEffect(() => {
		setCalendarViewEvents();

		// To make today button click work
		if (!moment(highlightedDate).isSame(initialDate, 'month')) {
			setInitialDate(highlightedDate);
		}
	}, [highlightedDate, initialDate, setCalendarViewEvents]);

	const containerImageMap = getImageMap(container.images);
	const headerImage = containerImageMap?.lmw ?? null;

	const selectedMonthEvents = useMemo(
		() => eventSectionList.filter(section => moment(section.title).isSame(moment(highlightedDate), 'month')),
		[eventSectionList, highlightedDate]
	);

	if (!containerData) {
		return null;
	}

	if (!eventMap.size) {
		return (
			<div
				style={{
					display: 'flex',
					width: screenWidth,
					height: screenHeight,
				}}
			>
				<NoEventsScreen />;
			</div>
		);
	}

	return (
		<EventsScreen
			calendarMaxDate={calendarDates.current.endDate}
			calendarMinDate={calendarDates.current.startDate}
			eventSectionList={selectedMonthEvents}
			fetchSection={setHighlightedDate}
			headerImage={headerImage}
			highlightedDate={highlightedDate}
			initialDate={initialDate} // app doesn't use this prop
			markedDates={markedDates}
			providerStyle={{
				maxHeight: PREVIEW_CONTENT_HEIGHT,
			}}
			screenWidth={screenWidth}
			settings={appSettings}
			onMonthChange={onMonthChange} // app doesn't use this prop
			onRowPress={noop}
		/>
	);
}

function getEventMap(container: ContainerPreviewData, campusId: string | null): Map<string, Event[]> {
	const { children } = container;
	if (!children) return new Map();

	const timestampNow = Math.floor(Date.now() / 1000);

	const events = children
		.filter(child => !child.hidden)
		.reduce<Event[]>((acc, child) => {
			if (child.__typename === 'ChildItem') {
				return acc.concat(getEventsForItem(container.id, child, timestampNow));
			}

			if (isChildContainerWithChildren(child)) {
				if (child.container.campus && child.container.campus?.id !== campusId) {
					return acc;
				}
				const nestedChildrenEvents = child.container.children.nodes
					.filter(nestedChild => !nestedChild.hidden)
					.filter((nestedChild): nestedChild is ChildItemFragment => nestedChild.__typename === 'ChildItem')
					.map(x => getEventsForItem(child.container.id, x, timestampNow));
				return acc.concat(nestedChildrenEvents.flat());
			}

			return acc;
		}, [])
		.sort((a, b) => moment(a.start_time).unix() - moment(b.start_time).unix());
	const eventMap = formatEvents(events);
	return eventMap;
}

function isChildContainerWithChildren(
	value: ChildItemFragment | ChildContainerFragment | ChildContainerWithChildrenFragment
): value is ChildContainerWithChildrenFragment {
	return value.__typename === 'ChildContainer' && 'children' in value.container;
}

function getEventsForItem(parentId: string, childItem: ChildItemFragment, timestampNow: number): Event[] {
	const isStartTimeInFuture = (startTime: number, endTime: number | null) =>
		startTime > timestampNow || (endTime && endTime > timestampNow);

	const { id, icon, name } = childItem;

	return childItem.item.properties.reduce<Event[]>((acc, property) => {
		if (property.__typename !== 'TimeframeProperty' || !property.timeframe.startTime) {
			return acc;
		}

		const { startTime, endTime, allDay } = property.timeframe;

		const validatedEndTime = endTime || getImpliedEndDate(startTime, allDay);

		const startTimeRounded = Math.floor(startTime.valueOf() / 1000);
		const endTimeRounded = Math.floor(validatedEndTime.valueOf() / 1000);

		if (isStartTimeInFuture(startTimeRounded, endTimeRounded)) {
			acc.push({
				uuid: id,
				start_time: startTimeRounded,
				end_time: endTimeRounded,
				all_day: allDay ? 1 : 0,
				parent: parentId,
				name,
				icon: icon ?? null,
				template: 'detail-plain',
				images: null,
				type: 'item',
				subtitle: null,
			});
		}
		return acc;
	}, []);
}
