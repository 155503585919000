import { PropsWithChildren, createContext, useContext, useMemo, useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { useShowNotification } from '@src/components/notification';
import { NotificationContent, SUCCESSFUL_KEY, FAILED_KEY } from '@src/components/notification/NotificationContent';
import { FeedProcessState, GetContainerFeedQuery, GetContainerFeedDocument } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { generatePath, combinePaths, ROUTE_PATHS } from '@src/router';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';

type FeedProcessContextType = {
	startListeningToFeed: (id: string, onResult?: (feedProcessState: FeedProcessState) => void) => void;
};

export const FeedProcessContext = createContext<FeedProcessContextType | null>(null);
FeedProcessContext.displayName = 'FeedProcessContext';

const POLL_INTERVAL = 5000;

function FeedProcessProvider({ children }: PropsWithChildren) {
	const showNotification = useShowNotification();
	const { translate } = useTranslation('appDesign');
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const client = useApolloClient();

	const pollFeedProcessState = useCallback(
		async (feedContainerId: string) => {
			const { data } = await client.query<GetContainerFeedQuery>({
				query: GetContainerFeedDocument,
				variables: {
					organizationKey,
					platformCampusKey,
					applicationId,
					containerId: feedContainerId,
				},
				fetchPolicy: 'network-only',
			});

			const feed = data.organization?.application?.container?.feed;

			return feed?.processState ?? FeedProcessState.Unknown;
		},
		[applicationId, client, organizationKey, platformCampusKey]
	);

	const startListeningToFeed = useCallback(
		(feedContainerId: string, onResult?: (feedProcessState: FeedProcessState) => void) => {
			if (onResult) {
				onResult(FeedProcessState.Requested);
			}
			const intervalId = setInterval(async () => {
				const containerChildrenPath = generatePath(
					combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP, ROUTE_PATHS.CONTAINER_CHILDREN),
					{
						organizationKey,
						containerId: feedContainerId,
					}
				);

				const containerSettingsPath = generatePath(
					combinePaths(ROUTE_PATHS.ORGANIZATION_ROOT, ROUTE_PATHS.APP, ROUTE_PATHS.CONTAINER_SETTINGS),
					{
						organizationKey,
						containerId: feedContainerId,
					}
				);
				const feedState = await pollFeedProcessState(feedContainerId);

				const showSuccessNotification = () => {
					showNotification({
						isOpen: true,
						type: 'success',
						title: translate('attribute.processingCompleteMessage.title'),
						content: (
							<NotificationContent linkPath={containerChildrenPath} translationKey={SUCCESSFUL_KEY} />
						),
					});
				};

				const showErrorNotification = () => {
					showNotification({
						isOpen: true,
						type: 'error',
						title: translate('attribute.processingFailedMessage.title'),
						content: <NotificationContent linkPath={containerSettingsPath} translationKey={FAILED_KEY} />,
						persistent: true,
					});
				};

				if (feedState === FeedProcessState.Complete) {
					showSuccessNotification();
					clearInterval(intervalId);
				}

				if (feedState === FeedProcessState.Failed) {
					showErrorNotification();
					clearInterval(intervalId);
				}

				if (onResult) {
					onResult(feedState);
				}
			}, POLL_INTERVAL);
		},
		[organizationKey, pollFeedProcessState, showNotification, translate]
	);

	const feedProcessContextValue = useMemo(() => ({ startListeningToFeed }), [startListeningToFeed]);

	return <FeedProcessContext.Provider value={feedProcessContextValue}>{children}</FeedProcessContext.Provider>;
}

function useFeedProcessContext() {
	const context = useContext(FeedProcessContext);
	if (!context) {
		throw new Error('useFeedProcessStateContext must be used within a FeedProcessContextProvider');
	}
	return context;
}

export { useFeedProcessContext, FeedProcessProvider };
