/* eslint-disable prettier/prettier */
import { useMemo } from 'react';

import { getTimeframeDefaultInput } from '@src/components/properties/helpers';
import { CardType, ItemTemplate, ItemType } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { Draggable } from '../types';
import {
	addPropertyTimeframe,
	addPropertyAddToContacts,
	addPropertyAddress,
	addPropertyShare,
	addPropertyWebsite,
	addPropertyTextHTML,
	addPropertyKeyMetrics,
	addPropertyCarousel,
	addPropertyGive,
	addPropertyCallToAction,
} from './property';

export const useDraggableItemsDefinition = () => {
	const { translate } = useTranslation('appDesign');

	const addItem: Draggable = useMemo(
		() => ({
			icon: 'file-1',
			label: 'Item',
			name: 'add_item',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.defaultItemName'),
						type: ItemType.Default,
						template: ItemTemplate.DetailPlain,
						icon: 'file-1',
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	const addItemEvent: Draggable = useMemo(
		() => ({
			icon: 'calendar-1',
			label: 'Event',
			name: 'add_item_event',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.eventName'),
						type: ItemType.Event,
						icon: 'calendar-1',
						template: ItemTemplate.DetailPlain,
					},
				},
				{
					...addPropertyTimeframe.actions[0],
					data: {
						...addPropertyTimeframe.actions[0].data,
						getPropertySpecificFields: getTimeframeDefaultInput,
						header: translate('defaultValue.propertyDefinition.timeFrameProperty.header'),
						actionBar: 1,
					},
				},
				{
					...addPropertyTextHTML.actions[0],
					data: {
						...addPropertyTextHTML.actions[0].data,
						header: translate('defaultValue.propertyDefinition.textHtmlProperty.header'),
					},
				},
				{
					...addPropertyCallToAction.actions[0],
					data: {
						...addPropertyCallToAction.actions[0].data,
						header: translate('defaultValue.propertyDefinition.callToActionProperty.header'),
						bos: {
							isExternal: true,
							label: translate('callToAction.eventLabel'),
						},
					},
				},
				{
					...addPropertyAddress.actions[0],
					data: {
						...addPropertyAddress.actions[0].data,
						header: translate('defaultValue.propertyDefinition.addressProperty.header'),
					},
				},
				{
					...addPropertyShare.actions[0],
					data: {
						...addPropertyShare.actions[0].data,
						header: translate('defaultValue.propertyDefinition.shareProperty.header'),
						actionBar: 2,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	const addItemDestination: Draggable = useMemo(
		() => ({
			icon: 'building-2',
			label: 'Destination',
			name: 'add_item_destination',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.destinationName'),
						type: ItemType.Destination,
						icon: 'building-2',
						template: ItemTemplate.DetailPlain,
					},
				},
				{
					...addPropertyAddress.actions[0],
					data: {
						...addPropertyAddress.actions[0].data,
						header: translate('defaultValue.propertyDefinition.addressProperty.header'),
						actionBar: 1,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	const addItemContact: Draggable = useMemo(
		() => ({
			icon: 'user-2',
			label: 'Contact',
			name: 'add_item_contact',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.contactName'),
						type: ItemType.Contact,
						icon: 'user-2',
						template: ItemTemplate.DetailSquare,
					},
				},
				{
					...addPropertyAddToContacts.actions[0],
					data: {
						...addPropertyAddToContacts.actions[0].data,
						header: translate('defaultValue.propertyDefinition.addToContactsProperty.header'),
						actionBar: 1,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);

	const addItemMedia: Draggable = useMemo(
		() => ({
			icon: 'file-media',
			label: 'Media',
			name: 'add_item_media',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.mediaName'),
						type: ItemType.Media,
						icon: 'file-media',
						template: ItemTemplate.DetailFullNoOverlay,
					},
				},
				{
					...addPropertyShare.actions[0],
					data: {
						...addPropertyShare.actions[0].data,
						header: translate('defaultValue.propertyDefinition.shareProperty.header'),
						actionBar: 3,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	const addItemSermon: Draggable = useMemo(
		() => ({
			icon: 'custom-pulpit',
			label: 'Sermon',
			name: 'add_item_sermon',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.sermonName'),
						type: ItemType.Sermon,
						icon: 'custom-pulpit',
						template: ItemTemplate.DetailPlain,
					},
				},
				{
					...addPropertyShare.actions[0],
					data: {
						...addPropertyShare.actions[0].data,
						header: translate('defaultValue.propertyDefinition.shareProperty.header'),
						actionBar: 3,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	const addItemWebview: Draggable = useMemo(
		() => ({
			icon: 'cloud',
			label: 'Webview',
			name: 'add_item_webview',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.webviewName'),
						type: ItemType.Webview,
						icon: 'cloud',
						template: ItemTemplate.DetailWebview,
					},
				},
				{
					...addPropertyWebsite.actions[0],
					data: {
						...addPropertyWebsite.actions[0].data,
						header: translate('defaultValue.propertyDefinition.websiteProperty.header'),
						actionBar: 0,
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);

	const addItemImpact: Draggable = useMemo(
		() => ({
			icon: 'hand-eco',
			label: 'Impact',
			name: 'add_item_impact',
			actions: [
				{
					action_type: 'create',
					model: 'item',
					data: {
						name: translate('defaultValue.itemDefinition.impactItem.name'),
						type: ItemType.Impact,
						icon: 'hand-eco',
						template: ItemTemplate.DetailPlain,
					},
				},
				{
					...addPropertyTextHTML.actions[0],
					data: {
						...addPropertyTextHTML.actions[0].data,
						header: translate('defaultValue.propertyDefinition.textHtmlProperty.header'),
					},
				},
				{
					...addPropertyKeyMetrics.actions[0],
					data: {
						...addPropertyKeyMetrics.actions[0].data,
						header: translate('defaultValue.propertyDefinition.keyMetricsProperty.header'),
					},
				},
				{
					...addPropertyCarousel.actions[0],
					data: {
						...addPropertyCarousel.actions[0].data,
						header: translate('defaultValue.propertyDefinition.carouselProperty.header'),
					},
				},
				{
					...addPropertyTextHTML.actions[0],
					data: {
						...addPropertyTextHTML.actions[0].data,
						header: translate('defaultValue.propertyDefinition.textHtmlProperty.header'),
					},
				},
				{
					...addPropertyGive.actions[0],
					data: {
						...addPropertyGive.actions[0].data,
						header: translate('defaultValue.propertyDefinition.giveProperty.header'),
						actionBar: 1,
					},
				},
				{
					...addPropertyWebsite.actions[0],
					data: {
						...addPropertyWebsite.actions[0].data,
						header: translate('defaultValue.itemDefinition.impactItem.properties.websiteProperty.header'),
						icon: 'hand-grab-2',
						bos: {
							isExternal: true,
							data: 'Volunteer',
						},
						actionBar: 2,
						hideOriginalProperty: true,
					},
				},
				{
					...addPropertyShare.actions[0],
					data: {
						...addPropertyShare.actions[0].data,
						header: translate('defaultValue.propertyDefinition.shareProperty.header'),
						actionBar: 3,
					},
				},
				{
					action_type: 'create',
					model: 'card_definition',
					data: {
						preferredCardType: CardType.Impact,
						featured: false,
						callToActionText: 'Learn more',
						showProperties: {
							keyMetrics: true,
							title: true,
						},
						genericContent: {},
					},
				},
			],
			unique: false,
			type: 'item',
		}),
		[translate]
	);
	return {
		addItem,
		addItemEvent,
		addItemDestination,
		addItemContact,
		addItemMedia,
		addItemSermon,
		addItemWebview,
		addItemImpact,
	};
};
