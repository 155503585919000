import React, { useRef } from 'react';

import { SkeletonForm } from '@pushpay/skeleton';

import { Banner } from '@src/components/banner';
import { PageNotFoundError } from '@src/components/errors';
import {
	useContentDispatchContext,
	ContentType,
	useBreadcrumbDispatchContext,
	useErrorContext,
	CarouselPendingUploadImagesProvider,
} from '@src/context';
import { apolloClient } from '@src/graphql/client';
import { GetItemDocument, GetItemQuery, useGetItemQuery, ItemType } from '@src/graphql/generated';
import { useMyApp } from '@src/myContext';
import { useParams } from '@src/router';
import { useItemAndContainerDeleteMutation, useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useItemAndContainerVisibilityMutation } from '@src/shared/hooks/useItemAndContainerVisibilityMutation';
import { validateItemProperties } from '@src/shared/validation';

import { CONTAINER_CHILDREN_PAGING_SIZE } from '../constants';
import { ItemSettingsForm, ItemSettingsFormRefType } from './ItemSettingsForm';

type LoaderParams = {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
	containerId: string;
	itemId: string;
};

export function loader({ organizationKey, platformCampusKey, applicationId, containerId, itemId }: LoaderParams) {
	apolloClient.client?.query<GetItemQuery>({
		query: GetItemDocument,
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
			itemId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-first',
	});
}

export function ItemSettings() {
	const { containerId, itemId } = useParams<{
		organizationKey: string;
		containerId: string;
		itemId: string;
	}>();
	const { organizationKey, platformCampusKey } = useGetQueryAndMutationVars();
	const { currentApp, dhs } = useMyApp();
	const itemSettingFormRef = useRef<ItemSettingsFormRefType>(null);
	const { useSetContent } = useContentDispatchContext();
	const { useUpdatePageNotFoundError } = useErrorContext();
	const { loading, error, data, refetch } = useGetItemQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId: currentApp?.id || '',
			containerId,
			itemId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-and-network',
	});
	const isLoadingData = loading && !data;
	const { generateOnToggleVisibilityHandler } = useItemAndContainerVisibilityMutation({
		organizationKey,
		platformCampusKey,
		applicationId: currentApp?.id || '',
	});
	const onToggleVisibility = generateOnToggleVisibilityHandler(itemId, 'item', containerId);

	const { useQueryData } = useBreadcrumbDispatchContext();
	useQueryData({ data, type: ContentType.ITEM }, isLoadingData);

	const { generateDeleteHandler } = useItemAndContainerDeleteMutation({
		organizationKey,
		platformCampusKey,
		applicationId: currentApp?.id || '',
	});

	useSetContent({
		type: ContentType.ITEM,
		contentData: data,
		loading: isLoadingData,
		error: !!error,
	});

	const container = data?.organization?.application?.container;
	const item = container?.item;
	const isItemNotFound = !isLoadingData && item === null;
	const isManualEvent = item?.type === ItemType.Event && !item?.feedId;

	const parentContainerFeedType = container?.feed?.__typename;
	const isInFeedContainer = !!parentContainerFeedType;
	const isFeedItem = isInFeedContainer && !isManualEvent;

	const { hasInvalidTimeframeProperties, hasNoTimeframeProperty } = validateItemProperties(item);

	const onDeleteHandler = () => {
		itemSettingFormRef?.current?.resetBeforeDelete();
		generateDeleteHandler({
			id: itemId,
			type: 'item',
			parentId: container?.id,
			onCompleted: itemSettingFormRef?.current?.onConfirmDelete,
		})();
	};

	useUpdatePageNotFoundError(isItemNotFound);

	return (
		<React.Suspense fallback={<p>loading...</p>}>
			{isItemNotFound && <PageNotFoundError />}
			{data && item && (
				<div>
					<Banner
						data-pp-at-target="item-banner"
						hasInvalidTimeframeProperties={hasInvalidTimeframeProperties}
						hasNoTimeframeProperty={hasNoTimeframeProperty}
						hideVisibilityToggle={!!item.feedId}
						isEditable={false}
						isFeedItem={isFeedItem}
						isHidden={(item.isHidden && !item.feedId) || false}
						name={item.name || ''}
						type="item"
						isDeletable
						onDelete={onDeleteHandler}
						onToggleVisibility={onToggleVisibility}
					/>
					{isLoadingData ? (
						<SkeletonForm rows={5} sections={2} />
					) : (
						<CarouselPendingUploadImagesProvider>
							<ItemSettingsForm
								ref={itemSettingFormRef}
								isDHSEnabled={dhs.enabled}
								isFeedItem={isFeedItem}
								item={item}
								parentContainerFeedType={parentContainerFeedType}
								onDiscard={() => {
									refetch();
								}}
							/>
						</CarouselPendingUploadImagesProvider>
					)}
				</div>
			)}
		</React.Suspense>
	);
}
