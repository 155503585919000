import { useEffect } from 'react';

import { ComponentProps } from '@pushpay/types';

import { getFeedInitialData } from '@src/pages/containerSettings/utils/containerSettingFormInitialDataGenerator';

import { useFeedAttributeDirtyContext } from '../feedAttributeDirtyContext';
import { ContainerFeed, YouTubeFeedInputFields } from '../types';
import { FeedCommonFields, FeedCommonFieldsProps } from './FeedCommonFields';

export type YouTubeFeedProps = ComponentProps<
	{
		feed: ContainerFeed;
		youTubeFields: YouTubeFeedInputFields;
	} & Omit<FeedCommonFieldsProps, 'urlField' | 'propertiesField' | 'feedProcessState'>,
	typeof undefined
>;
export const YouTubeFeedFields = ({ youTubeFields, feed, processFeed, enableDownloadButton }: YouTubeFeedProps) => {
	const { youtubeFeed: originalValue } = getFeedInitialData(feed);
	const { setDirty } = useFeedAttributeDirtyContext();
	const isDirty =
		originalValue &&
		(youTubeFields.url.value !== originalValue.url || youTubeFields.properties.value !== originalValue.properties);

	useEffect(() => {
		setDirty(!!isDirty);
	}, [isDirty, setDirty]);
	return (
		<>
			<FeedCommonFields
				enableDownloadButton={!isDirty && enableDownloadButton}
				feedProcessState={feed?.processState}
				processFeed={processFeed}
				propertiesField={youTubeFields.properties}
				urlField={youTubeFields.url}
			/>
		</>
	);
};
