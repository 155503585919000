import { memo, useState, useEffect, useCallback, useMemo } from 'react';

import { CheckboxField, useMutateField } from '@pushpay/forms';
import { Option, Select } from '@pushpay/inputs';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ItemPropertyInput } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';

import { ItemPropertiesFields } from '../types';

export type ActionBarPositionField =
	ItemPropertiesFields[number][keyof ItemPropertyInput]['baseProperty']['actionBar']['position'];

export type HideOriginalPropertyField =
	| ItemPropertiesFields[number][keyof ItemPropertyInput]['baseProperty']['actionBar']['hideOriginalProperty']
	| null;

type OptionsWithField = Option<string> & {
	field: ActionBarPositionField;
};

type ItemActionProps = {
	hideOriginalPropertyField: HideOriginalPropertyField;
	actionBarPositionField: ActionBarPositionField;
	options: OptionsWithField[];
	position: number;
	disabled?: boolean;
	selectedValue: string;
};

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	checkboxInput: {
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	label: {
		font: theme.typography['text-4'],
	},
	checkboxInputLabel: {
		lineHeight: theme.SPACING.SMALL,
		marginLeft: theme.SPACING.XSMALL,
	},
	content: {
		flex: '0 0 0',
	},
}));

export const ItemAction = memo(
	({
		disabled = false,
		hideOriginalPropertyField,
		options,
		position,
		actionBarPositionField: currentPositionField,
		selectedValue,
		classes: classesProp,
	}: ComponentProps<ItemActionProps, typeof useStyles>) => {
		const classes = useStyles(classesProp);
		const { translate } = useTranslation('appDesign');

		const [selectedPositionField, setSelectedPositionField] =
			useState<ActionBarPositionField>(currentPositionField);
		const [positionValueChanged, setPositionValueChanged] = useState<boolean>(false);

		const { mutateField: mutateCurrentField } = useMutateField(currentPositionField);
		const { mutateField: mutateSelectedField } = useMutateField(selectedPositionField);

		const fieldAndValueMap = useMemo(() => new Map(options.map(option => [option.value, option.field])), [options]);

		const onChangeHandler = useCallback(
			(newValue: string) => {
				const isSamePropertySelected = newValue === selectedValue;
				if (isSamePropertySelected) {
					return;
				}
				const newField = fieldAndValueMap.get(newValue) as ActionBarPositionField;
				setSelectedPositionField(newField);
				setPositionValueChanged(true);
			},
			[fieldAndValueMap, selectedValue]
		);

		useEffect(() => {
			if (!positionValueChanged) {
				return;
			}
			mutateCurrentField(0);
			mutateSelectedField(position);
			setPositionValueChanged(false);
		}, [position, positionValueChanged, mutateCurrentField, mutateSelectedField, setPositionValueChanged]);

		const hasOnlyDefaultOption = options.length === 1;
		return (
			<>
				<Select
					data-pp-at-target={`item-action-bar-${position}`}
					disabled={hasOnlyDefaultOption || disabled}
					getOptionKey={option => `${option.value}`}
					id={`select-item-action-bar-${position}`}
					options={options.map(({ display, value }) => ({ display, value }))}
					value={selectedValue}
					onChange={onChangeHandler}
				/>
				{hideOriginalPropertyField && (
					<CheckboxField
						classes={{
							root: classes.checkboxInput,
							label: clsx(classes.checkboxInputLabel, classes.label),
							content: classes.content,
						}}
						data-pp-at-target={`item-action-bar-${position}-hide-original-property`}
						field={hideOriginalPropertyField}
						id={`item-action-bar-${position}-hide-original-property`}
						label={translate('itemSetting.itemActionHideProperty')}
						readOnly={disabled}
						showLabel
					/>
				)}
			</>
		);
	}
);
