import { useCallback } from 'react';

import { FormState, getDynamicFieldsItems, useMutateField } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ItemTemplate, PropertyType, SaveItemSettingsSchema } from '@src/graphql/generated';
import { ItemPropertiesFields } from '@src/pages/itemSettings/types';
import { propertyInputTypeObj } from '@src/pages/itemSettings/utils';

import { PropertyInputType } from '../properties/types';
import { getItemLayout, getTemplateByLayout, LayoutKeys, Options, TemplatesType } from './layoutDefinition';
import { LayoutSettingsComponent } from './LayoutSettingsComponent';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		marginBottom: theme.SPACING.SMALL,
	},
}));

export type ItemLayoutSettingsProps = ComponentProps<
	{
		inputField: FormState<SaveItemSettingsSchema['input']>;
		disableOptions: boolean;
		isDisabled?: boolean;
	},
	typeof useStyles
>;

export const ItemLayoutSettings = ({
	classes: classesProp,
	inputField,
	isDisabled,
	disableOptions,
}: ItemLayoutSettingsProps) => {
	const { template: templateField, properties: propertyFields } = inputField;
	const classes = useStyles(classesProp);
	const { mutateField: mutateTemplate } = useMutateField(templateField);
	const onLayoutChange = useCallback(
		(selectedLayout: LayoutKeys, options: Options) => {
			mutateTemplate(getTemplateByLayout(selectedLayout, options) as ItemTemplate);
		},
		[mutateTemplate]
	);

	const templateType = templateField.value as TemplatesType;

	if (!templateType) return null;

	const propertyInputTypes = getPropertyInputTypes(propertyFields);
	const videoInputType = propertyInputTypeObj[PropertyType.Video];
	const audioInputType = propertyInputTypeObj[PropertyType.Audio];

	const hasAudioVideoProperty = propertyInputTypes.some(inputType =>
		[videoInputType, audioInputType].includes(inputType)
	);

	const { layouts, layoutType, layoutOptions } = getItemLayout(templateType, hasAudioVideoProperty);

	return (
		layouts &&
		layoutType && (
			<LayoutSettingsComponent
				classes={{ root: classes.root }}
				data-pp-at-target="layout-settings"
				isDisabled={isDisabled}
				isPlaceTemplate={templateType.includes('TIDBIT')} // should update to check the template type is Place or not.
				layoutOptions={disableOptions ? null : layoutOptions}
				layouts={layouts}
				layoutType={layoutType}
				onLayoutChange={onLayoutChange}
			/>
		)
	);
};

function getPropertyInputTypes(itemPropertyFields: ItemPropertiesFields): PropertyInputType[] {
	const fieldItems = getDynamicFieldsItems(itemPropertyFields);
	const propertyInputTypes = fieldItems.map(fieldItem => Object.keys(fieldItem)[0]) as PropertyInputType[];

	return [...new Set(propertyInputTypes)];
}
