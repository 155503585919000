import { useEffect, useId, useState } from 'react';

import { CheckboxField, FormState, SelectField, TextField, useFieldVisibility, useMutateField } from '@pushpay/forms';
import { Column, Columns, Stack, Tiles } from '@pushpay/layout';
import { clsx } from '@pushpay/styles';
import { useMediaBreakpoint } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableAttributes } from '@src/components/expandableAttributes';
import { CTAField } from '@src/components/formFields/CTAField';
import { FeaturedLabelField } from '@src/components/formFields/FeaturedLabelField';
import { ExpandableNotification } from '@src/components/notification';
import { SwitchWithTooltip } from '@src/components/switch/SwitchWithTooltip';
import { CardType, SaveItemSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import {
	GetItemCardDefinition,
	GetItemQueryProperties,
	getCardTypeOptions,
} from '@src/pages/itemSettings/components/DHSItemSetting.util';
import { FeedType } from '@src/pages/itemSettings/types';
import { useEvent } from '@src/utils';

import { useStyles } from './DHSItemSetting.style';

export type DHSItemSettingProps = {
	fields: FormState<SaveItemSettingsSchema>['input']['cardDefinition'];
	itemProperties?: GetItemQueryProperties;
	cardDefinition?: GetItemCardDefinition;
	parentContainerFeedType?: FeedType;
	isEventItem: boolean;
	isExpiredEventItem: boolean;
	isFeedItem: boolean;
};

export const DHSItemSetting = (
	props: Omit<ComponentProps<DHSItemSettingProps, typeof useStyles>, 'data-pp-at-target'>
) => {
	const {
		fields,
		itemProperties,
		cardDefinition,
		parentContainerFeedType,
		isEventItem,
		isExpiredEventItem,
		isFeedItem,
		classes: classesProp,
	} = props;

	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');
	const [enableDhs, setEnableDhs] = useState(!!cardDefinition);
	const isCTAEnabled = (
		[CardType.Intro, CardType.Special, CardType.Event, CardType.Impact] as Array<CardType | undefined | null>
	).includes(fields.cardType.value);
	const isImpactItem = fields.cardType.value === CardType.Impact;

	const { mutateField: updateIsFeatured } = useMutateField(fields.isFeatured);
	const { visible, setVisibility: handleOnDHSToggle } = useFieldVisibility(fields);

	const featuredToggledId = useId();

	const syncFieldVisibility = useEvent((val: boolean) => {
		if (enableDhs !== val) {
			setEnableDhs(val);
		}
	});

	useEffect(() => {
		syncFieldVisibility(visible);
	}, [syncFieldVisibility, visible]);

	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');

	const dataPpAtTarget =
		parentContainerFeedType && isFeedItem ? `${parentContainerFeedType}-item-dhs-options` : 'dhs-options';

	const isDisabled = isExpiredEventItem;

	return (
		<div className={classes.root}>
			<div className={classes.title}>{translate('itemSetting.dhsSetting.dhsTitle')}</div>
			{isEventItem && (
				<ExpandableNotification
					content={translate('itemSetting.dhsSetting.dhsExpiredTooltip.content')}
					title={translate('itemSetting.dhsSetting.dhsExpiredTooltip.title')}
				/>
			)}
			<ExpandableAttributes
				classes={{ body: classes.expandableAttributesBody }}
				data-pp-at-target={dataPpAtTarget}
				disabled={isDisabled}
				expand={enableDhs}
				extraAction={
					<SwitchWithTooltip
						checked={fields.isFeatured.value && enableDhs}
						data-pp-at-target={`${dataPpAtTarget}-featured-toggle`}
						disabled={isDisabled}
						id={featuredToggledId}
						tooltipContent={translate('settings.featuredTooltip')}
						onChange={updateIsFeatured}
					>
						{translate('settings.featured')}
					</SwitchWithTooltip>
				}
				toggleName={translate('itemSetting.dhsSetting.dhsToggleName')}
				tooltipContent={translate('itemSetting.dhsSetting.dhsTooltip')}
				onExpandChange={handleOnDHSToggle}
			>
				<Stack classes={{ stackItem: classes.stackItem }} space={0}>
					<Columns>
						<Column classes={{ root: classes.column }}>
							<Tiles
								classes={{
									tile: classes.tile,
								}}
								columns={isTablet ? [1] : [2]}
							>
								<SelectField<CardType | null>
									classes={{ label: classes.label }}
									data-pp-at-target="dhs-options-card-type"
									defaultValue={CardType.Default}
									field={fields.cardType}
									label={translate('itemSetting.dhsSetting.dhsCardTypeLabel')}
									labelPosition="top"
									options={getCardTypeOptions(isImpactItem, itemProperties)}
									readOnly={isImpactItem}
									tooltip={
										isImpactItem
											? translate('itemSetting.dhsSetting.dhsImpactCardTypeLabelTooltip')
											: translate('itemSetting.dhsSetting.dhsCardTypeLabelTooltip')
									}
									showLabel
								/>
								{!isImpactItem && (
									<TextField
										classes={{
											label: classes.label,
										}}
										data-pp-at-target="dhs-options-subtitle"
										field={fields.genericContent?.subtitle}
										label={translate('itemSetting.dhsSetting.dhsSubTitleLabel')}
										labelPosition="top"
										placeholder={translate('itemSetting.dhsSetting.subTitlePlaceholder')}
										showLabel
									/>
								)}
							</Tiles>
							<Tiles columns={[1]}>
								<TextField
									classes={{ label: classes.label }}
									data-pp-at-target="dhs-options-summary"
									field={fields.genericContent?.summary}
									label={translate('itemSetting.dhsSetting.dhsSummaryLabel')}
									labelPosition="top"
									placeholder={translate('itemSetting.dhsSetting.summaryPlaceholder')}
									showLabel
								/>
							</Tiles>
							{!isImpactItem && (
								<Tiles classes={{ tile: classes.checkboxTile }} columns={[4]}>
									<CheckboxField
										classes={{
											root: classes.checkboxInput,
											label: clsx(classes.checkboxInputLabel, classes.label),
										}}
										field={fields.showProperties?.publishedTime}
										label={translate('itemSetting.dhsSetting.dhsShowPublishedTime')}
										showLabel
									/>
									<CheckboxField
										classes={{
											root: classes.checkboxInput,
											label: clsx(classes.checkboxInputLabel, classes.label),
										}}
										field={fields.showProperties?.category}
										label={translate('itemSetting.dhsSetting.dhsShowCategory')}
										showLabel
									/>
								</Tiles>
							)}
							{isImpactItem && (
								<Tiles classes={{ tile: classes.checkboxTile }} columns={[4]}>
									<CheckboxField
										classes={{
											root: classes.checkboxInput,
											label: clsx(classes.checkboxInputLabel, classes.label),
										}}
										field={fields.showProperties?.title}
										label={translate('itemSetting.dhsSetting.dhsShowTitle')}
										showLabel
									/>
									<CheckboxField
										classes={{
											root: classes.checkboxInput,
											label: clsx(classes.checkboxInputLabel, classes.label),
										}}
										field={fields.showProperties?.keyMetrics}
										label={translate('itemSetting.dhsSetting.dhsShowKeyMetrics')}
										showLabel
									/>
								</Tiles>
							)}
							{isCTAEnabled && (
								<Tiles columns={[2]}>
									<CTAField
										cardDefinitionType={isImpactItem ? 'impact' : 'base'}
										field={fields.callToActionText}
									/>
								</Tiles>
							)}
							{!!fields.isFeatured.value && (
								<Tiles columns={[2]}>
									<FeaturedLabelField field={fields.featuredLabel} />
								</Tiles>
							)}
						</Column>
					</Columns>
				</Stack>
			</ExpandableAttributes>
		</div>
	);
};
