import React, { useState, useEffect } from 'react';

import { FormState, NumberField, RuleType, SelectField, useFieldVisibility, useMutateField } from '@pushpay/forms';
import { Stack, Tiles } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableAttributes } from '@src/components/expandableAttributes';
import { CTAField } from '@src/components/formFields/CTAField';
import { FeaturedLabelField } from '@src/components/formFields/FeaturedLabelField';
import {
	CallToActionType,
	CardType,
	SaveContainerSettingsSchema,
	useGetItemPropertiesQuery,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n';
import { useParams } from '@src/router';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useEvent } from '@src/utils';

import { DataMappingField } from './DataMappingField';
import { ContainerSettings } from './type';
import { getCardTypeOptions } from './utils';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	titleTile: {
		paddingTop: 0,
	},
	label: {
		font: theme.typography['text-4'],
		width: 'auto',
		color: theme.colors['text-default'],
		marginBottom: theme.SPACING.XSMALL,
	},
}));

type ContainerCardDefinitionProp = ComponentProps<
	{
		fields: FormState<SaveContainerSettingsSchema>['input']['cardDefinition'];
		containerSettings: ContainerSettings | null;
	},
	typeof useStyles
>;

export const MAX_DHS_ITEMS = 20;

export const ContainerCardDefinition = ({
	fields,
	containerSettings,
	classes: classesProp,
}: ContainerCardDefinitionProp) => {
	const classes = useStyles(classesProp);
	const { containerId } = useParams<{ containerId: string }>();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const { translate } = useTranslation('appDesign');
	const isFeedContainer = containerSettings?.feed !== null;
	const hasChildren = !!containerSettings?.children?.nodes?.length;
	const itemId = containerSettings?.children.nodes.find(x => x.__typename === 'ChildItem')?.id;
	const { data: getItemPropertiesQueryResult } = useGetItemPropertiesQuery({
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
			itemId: itemId as string,
		},
		skip: !isFeedContainer || !hasChildren || !itemId,
		fetchPolicy: 'cache-and-network',
	});

	const [expand, setExpand] = useState(
		isFeedContainer &&
			containerSettings?.cardDefinition !== null &&
			hasChildren &&
			fields.cardType.value !== null &&
			!!getItemPropertiesQueryResult
	);

	const isCTAEnabled = (
		[CardType.Intro, CardType.Special, CardType.Event] as Array<CardType | undefined | null>
	).includes(fields.cardType.value);

	const { visible, setVisibility: setCardDefinitionVisibility } = useFieldVisibility(fields);
	const { mutateField: mutateCallToActionField } = useMutateField(fields.callToActionText);

	const syncFieldVisibility = useEvent((val: boolean) => {
		if (expand !== val) {
			setExpand(val);
		}
	});

	useEffect(() => {
		syncFieldVisibility(visible);
	}, [syncFieldVisibility, visible]);

	useEffect(() => {
		if (isCTAEnabled && fields.callToActionText.value === null) {
			mutateCallToActionField(CallToActionType.ReadMore);
		}
	}, [fields.callToActionText.value, isCTAEnabled, mutateCallToActionField]);

	const item = getItemPropertiesQueryResult?.organization?.application?.container?.item;
	return (
		<ExpandableAttributes
			classes={{ root: classes.root }}
			data-pp-at-target="container-dhs"
			disabled={!isFeedContainer || !hasChildren}
			expand={expand}
			id="container-dhs-toggle"
			title={translate('settings.container.dhs.title')}
			toggleName={translate('settings.container.dhs.toggle')}
			tooltipContent={translate('settings.container.dhs.titleTooltip')}
			onExpandChange={setCardDefinitionVisibility}
		>
			<Stack>
				<Tiles classes={{ tile: classes.titleTile }} columns={[2]}>
					<SelectField
						classes={{ label: classes.label }}
						data-pp-at-target="cardType"
						defaultValue={CardType.Default}
						extraValidationRules={[{ type: RuleType.Required }]}
						field={fields.cardType}
						label={translate('itemSetting.dhsSetting.dhsCardTypeLabel')}
						labelPosition="top"
						options={getCardTypeOptions(containerSettings?.cardDefinition?.dataMap, item?.properties)}
						tooltip={translate('settings.container.dhs.cardTypeTooltip')}
						showLabel
					/>
					<NumberField
						classes={{ label: classes.label }}
						extraValidationRules={[{ type: RuleType.Required }]}
						field={fields.maxItems}
						label={translate('itemSetting.dhsSetting.dhsCardMaxItems')}
						labelPosition="top"
						max={MAX_DHS_ITEMS}
						min={1}
						numberInputProps={{
							onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
								if (event.key === '.' || event.key === '-') {
									event.preventDefault();
									event.stopPropagation();
								}
							},
						}}
						tooltip={translate('settings.container.dhs.maxLimitTooltip', {
							maxDHSCardLimit: MAX_DHS_ITEMS,
						})}
						showLabel
					/>
				</Tiles>
				<DataMappingField
					cardType={fields.cardType.value}
					field={fields.dataMap}
					itemProperties={item?.properties || []}
				/>
				{isCTAEnabled && (
					<Tiles classes={{ tile: classes.titleTile }} columns={[2]}>
						<CTAField cardDefinitionType="base" field={fields.callToActionText} />
					</Tiles>
				)}
				<Tiles classes={{ tile: classes.titleTile }} columns={[2]}>
					<FeaturedLabelField field={fields.featuredLabel} />
				</Tiles>
			</Stack>
		</ExpandableAttributes>
	);
};
