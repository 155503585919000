import { useRef, useState } from 'react';

import { DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';

import { useExpand } from '@pushpay/expand';
import { getDynamicFieldsItems } from '@pushpay/forms';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ExpandableCard } from '@src/components/expandableCard';
import { useMutateProperties } from '@src/components/properties/hooks';
import { SortableContext } from '@src/context';
import { useDraggablesDataContext } from '@src/context/draggableData/draggablesDataContext';
import { useScrollElementIntoViewActions } from '@src/context/scrollElementIntoViewContext';
import { useTranslation } from '@src/i18n';
import { ItemPropertiesFields } from '@src/pages/itemSettings/types';
import { getSortedPropertiesFields, getPropertiesIds, propertyInputTypeObj } from '@src/pages/itemSettings/utils';
import { useDragAutoScroll } from '@src/shared/hooks';

import { ExpanderButton } from '../buttons';
import { NewPropertyDroppableArea } from '../droppable/NewPropertyDroppableArea';
import { PropertyProvider, usePropertyListContext } from './contexts';
import { getPropertyHeader, getPropertyIcon } from './helpers';
import { useAddProperty } from './hooks';
import { Property } from './Property';
import { PropertyInputType } from './types';

const useStyles = createUseStyles((theme: Theme) => ({
	body: {
		paddingTop: 0,

		'& > :not(:last-child)': {
			marginBottom: theme.SPACING.SMALL,
		},
	},
	card: {
		marginTop: theme.SPACING.MEDIUM,
		marginBottom: theme.SPACING.XLARGE,
	},
	title: {
		font: theme.typography['heading-3'],
	},
}));

export type PropertyPanelProps = ComponentProps<
	{
		propertiesFields: ItemPropertiesFields;
	},
	typeof undefined
>;

export const PropertyPanel = ({ propertiesFields }: PropertyPanelProps) => {
	const { draggables } = useDraggablesDataContext();
	const { translate } = useTranslation('appDesign');
	const propertyContainerRef = useRef(null);

	const unsortedDynamicFields = getDynamicFieldsItems(propertiesFields);
	const sortedDynamicFields = getSortedPropertiesFields(unsortedDynamicFields);

	const onAddingPropertyDropHandler = useAddProperty();
	const { disabledForFeedItem } = usePropertyListContext();

	const { onAddPropertyHandler, onUpdatePropertyPositionsHandler, onRemovePropertyHandler } = useMutateProperties(
		sortedDynamicFields,
		propertiesFields.itemTemplate,
		propertiesFields.path
	);

	const [isDragging, setIsDragging] = useState(false);
	const [propertyExpandStatus, togglePropertyExpandStatus] = useExpand('preexpanded');
	const classes = useStyles(undefined);

	useDragAutoScroll(propertyContainerRef, true);
	const { setElementIdToScrollTo } = useScrollElementIntoViewActions();

	const isChangingPosition = (fromPropertyId: string | undefined, toPropertyId: string | undefined) =>
		fromPropertyId && toPropertyId;

	const isAddingNewProperty = (event: DragEndEvent) =>
		!!event.active?.data?.current?.name?.startsWith('add_property_');

	const onAddingProperty = (event: DragEndEvent) => {
		const draggingItemData = draggables.find(item => item.name === event.active?.data?.current?.name);

		if (draggingItemData) {
			const shouldBeAddedAtEnd = !event.over?.data.current;
			const tempPosition = shouldBeAddedAtEnd ? sortedDynamicFields.length : 0;
			const newPropertyId = crypto.randomUUID();

			setElementIdToScrollTo(newPropertyId);

			onAddingPropertyDropHandler(event, draggingItemData, onAddPropertyHandler, tempPosition, newPropertyId);
		}
	};

	useDndMonitor({
		onDragStart() {
			setIsDragging(sortedDynamicFields.length > 0);
		},
		onDragEnd(event: DragEndEvent) {
			setIsDragging(false);

			if (!sortedDynamicFields || !event.over || !event.active) return;

			const fromPropertyId = event.active.data.current?.id;
			const toPropertyId = event.over?.data.current?.id;

			if (isChangingPosition(fromPropertyId, toPropertyId)) {
				onUpdatePropertyPositionsHandler(fromPropertyId, toPropertyId);
				return;
			}

			if (isAddingNewProperty(event)) {
				onAddingProperty(event);
			}
		},
	});

	return (
		<div ref={propertyContainerRef}>
			<ExpandableCard
				actions={
					sortedDynamicFields.length > 0 ? (
						<ExpanderButton
							displayStyle="text"
							expanded={propertyExpandStatus !== 'collapsed'}
							toggle={togglePropertyExpandStatus}
						>
							<span>
								{propertyExpandStatus === 'collapsed'
									? translate('property.expandAll')
									: translate('property.collapseAll')}
							</span>
						</ExpanderButton>
					) : null
				}
				classes={{
					body: classes.body,
					card: classes.card,
					title: classes.title,
				}}
				data-pp-at-target="property-panel-expand-icon"
				isCollapsible={false}
				isDisabled={disabledForFeedItem}
				showDragBorder={isDragging}
				title={translate('property.title')}
			>
				<SortableContext items={getPropertiesIds(sortedDynamicFields)} strategy={verticalListSortingStrategy}>
					{sortedDynamicFields.map(dynamicPropertyField => {
						const [[propertyInputType, propertyField]] = Object.entries(dynamicPropertyField);
						const propertyId = propertyField.baseProperty.id.value;

						const deleteHandler = () => {
							if (propertyId) {
								onRemovePropertyHandler(propertyId);
							}
						};

						const icon = getPropertyIcon(propertyInputType as any, propertyField);
						const header = getPropertyHeader(propertyInputType as any, propertyField);
						const isFirstOfType =
							sortedDynamicFields
								.map(field => {
									const [[type, property]] = Object.entries(field);
									return { id: property.baseProperty.id.value, type };
								})
								.find(field => field.type === propertyInputType)?.id === propertyId;

						const timeframeProperties = sortedDynamicFields.filter(field => {
							const [[type]] = Object.entries(field);
							return type === propertyInputTypeObj.TIMEFRAME;
						});
						const hasOnlyOneTimeframeProperty = timeframeProperties.length === 1;

						return (
							<PropertyProvider
								key={propertyField.baseProperty.id.value}
								hasOnlyOneTimeframeProperty={hasOnlyOneTimeframeProperty}
								header={header}
								icon={icon || 'property'}
								isFirstOfType={isFirstOfType}
								propertyExpandStatus={propertyExpandStatus}
							>
								<Property
									data-pp-at-target="property"
									propertyField={propertyField}
									propertyInputType={propertyInputType as PropertyInputType}
									onDelete={deleteHandler}
								/>
							</PropertyProvider>
						);
					})}
				</SortableContext>
				<NewPropertyDroppableArea showDropAreaMessage={!sortedDynamicFields.length} />
			</ExpandableCard>
		</div>
	);
};
