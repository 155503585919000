import { useApolloClient } from '@apollo/client';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';

import { EmptyTableIllustration } from '@pushpay/illustration';
import { Link } from '@pushpay/link';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { Banner } from '@src/components/banner';
import { CardList, CardProps } from '@src/components/card';
import { useDraggablesDefinition } from '@src/components/draggable';
import { EventContainerCover } from '@src/components/droppable/EventContainerCover';
import { NewItemDroppableArea } from '@src/components/droppable/NewItemDroppableArea';
import { IllustrationWithText } from '@src/components/illustrations';
import {
	ContentType,
	useBreadcrumbDispatchContext,
	useContentDispatchContext,
	SortableContext,
	useCopyAndMoveDispatchContext,
} from '@src/context';
import { apolloClient } from '@src/graphql/client';
import {
	ChildContainerFragment,
	ChildContainerFragmentDoc,
	ContainerType,
	GetContainerChildrenDocument,
	GetContainerChildrenQuery,
	useGetContainerChildrenQuery,
} from '@src/graphql/generated';
import { Trans, useTranslation } from '@src/i18n';
import { generatePath, ROUTE_PATHS, useParams } from '@src/router';
import { mapContainerChildToCard } from '@src/shared/cardUtils';
import { useItemAndContainerDeleteMutation, useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useItemAndContainerVisibilityMutation } from '@src/shared/hooks/useItemAndContainerVisibilityMutation';
import { getContainerCardType } from '@src/utils';

import { CONTAINER_CHILDREN_PAGING_SIZE } from '../constants';
import { useAppDesignPath } from '../shell';
import { ContainerChildrenLoader } from './ContainerChildrenLoader';
import { sortEventsData } from './ContainerChildrenUtils';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		marginRight: `-${theme.SPACING.LARGE}`,
		paddingRight: theme.SPACING.LARGE,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		position: 'relative',
	},
	loading: {
		padding: theme.SPACING.LARGE,
	},
	illustration: {
		width: '160px',
		height: '160px',
	},
	settingLink: {
		marginTop: theme.SPACING.SMALL,
	},
}));

type LoaderParams = {
	organizationKey: string;
	platformCampusKey?: string | null;
	applicationId: string;
	containerId: string;
};

export function loader({ organizationKey, platformCampusKey, applicationId, containerId }: LoaderParams) {
	return apolloClient.client?.query<GetContainerChildrenQuery>({
		query: GetContainerChildrenDocument,
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
			paging: { size: CONTAINER_CHILDREN_PAGING_SIZE },
		},
		fetchPolicy: 'cache-first',
	});
}

export function ContainerChildren({ initialPageSize = CONTAINER_CHILDREN_PAGING_SIZE, fetchMoreSize = 10 }) {
	const classes = useStyles(undefined);
	const { translate } = useTranslation('appDesign');
	const { useSetContent } = useContentDispatchContext();
	const { containerId } = useParams<'containerId'>();
	const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
	const client = useApolloClient();
	const cachedResultFromChildContainer = client.readFragment<ChildContainerFragment>({
		id: client.cache.identify({
			id: containerId,
			__typename: 'ChildContainer',
		}),
		variables: {
			organizationKey,
		},
		fragment: ChildContainerFragmentDoc,
		fragmentName: 'ChildContainer',
	});

	const { loading, error, data, networkStatus, fetchMore } = useGetContainerChildrenQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-first',
		variables: {
			organizationKey,
			platformCampusKey,
			applicationId,
			containerId,
			paging: { size: initialPageSize },
		},
	});
	const isLoadingData = loading && !data;

	let containerChildrenData = data;
	const isEventsContainer = data?.organization?.application?.container?.type === ContainerType.Events;

	containerChildrenData = isEventsContainer ? sortEventsData(data) : data;

	const { useQueryData } = useBreadcrumbDispatchContext();
	useQueryData({ data: containerChildrenData, type: ContentType.CONTAINER_CHILDREN }, isLoadingData);
	useSetContent({
		type: ContentType.CONTAINER_CHILDREN,
		contentData: containerChildrenData,
		loading: isLoadingData,
		error: !!error,
	});

	const { generateOnToggleVisibilityHandler } = useItemAndContainerVisibilityMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});

	const { generateDeleteHandler } = useItemAndContainerDeleteMutation({
		organizationKey,
		platformCampusKey,
		applicationId,
	});
	const { copyItemHandler } = useCopyAndMoveDispatchContext();

	const currentContainer = containerChildrenData?.organization?.application?.container;
	const containerCardType = getContainerCardType(currentContainer?.type);
	const parentContainerId = currentContainer?.parentContainer?.id;

	const { name, isHidden, isDeletable, onDeleteHandler } = getBannerProps();
	const { getContainerChildrenDraggables } = useDraggablesDefinition();
	const settingPath = useAppDesignPath(
		organizationKey,
		generatePath(ROUTE_PATHS.CONTAINER_SETTINGS, { containerId })
	);

	let listOfCards: CardProps[] = [];
	if (currentContainer) {
		const parentContainerType = currentContainer?.type;
		const isContainerFeedType = !!currentContainer?.feed;

		listOfCards =
			currentContainer?.children.nodes
				.map(node => {
					const canHaveChildren =
						node.__typename === 'ChildContainer' &&
						getContainerChildrenDraggables(node.container.type).length > 0;
					return mapContainerChildToCard(
						node,
						containerId,
						parentContainerType,
						applicationId,
						canHaveChildren
					);
				})
				.map(card => ({
					...card,
					isContainerFeedType,
					onToggleVisibility: generateOnToggleVisibilityHandler(card.id, card.type, containerId),
					onDelete: generateDeleteHandler({
						id: card.id,
						type: card.type,
						parentId: currentContainer?.id,
					}),
					onCopyItem: copyItemHandler({ card, containerId }),
				})) || [];
	}

	const children = currentContainer?.children;
	const onLoadMore = (size: number) =>
		fetchMore({
			variables: {
				paging: {
					size: fetchMoreSize ?? size,
					after: children?.paging.nextCursor,
				},
			},
		});

	const showEmptyMessage = !loading && listOfCards.length === 0;
	const isPreConfiguredContainer =
		currentContainer && getContainerChildrenDraggables(currentContainer.type).length === 0;
	const illustrationProps = {
		illustration: <EmptyTableIllustration background="white" className={classes.illustration} />,
		...(isPreConfiguredContainer
			? {
					text: (
						<Trans i18nKey="containerChildren.preConfiguredContainerText" namespace="appDesign">
							This container is pre-configured and does not support adding additional content
							<Link className={classes.settingLink} to={settingPath}>
								Go to the settings page
							</Link>
						</Trans>
					),
					title: translate('containerChildren.preConfiguredContainerTitle'),
			  }
			: {
					text: translate('containerChildren.emptyIllustrationText'),
					title: translate('containerChildren.emptyIllustrationTitle'),
			  }),
	};

	const isEventContainer = currentContainer?.type === ContainerType.Events;
	return (
		<div className={classes.root}>
			<Banner
				data-pp-at-target="container-children-banner"
				editPath={generatePath(ROUTE_PATHS.CONTAINER_SETTINGS, {
					containerId,
				})}
				isDeletable={isDeletable}
				isHidden={isHidden}
				name={name}
				type={containerCardType}
				isEditable
				isSticky
				onDelete={onDeleteHandler}
				onToggleVisibility={generateOnToggleVisibilityHandler(currentContainer?.id || '', 'container')}
			/>
			<div className={classes.content}>
				<ContainerChildrenLoader data={children} networkStatus={networkStatus} onLoadMore={onLoadMore}>
					<SortableContext items={listOfCards.map(x => x.id)} strategy={verticalListSortingStrategy}>
						<CardList
							key={containerId}
							cards={listOfCards}
							data-pp-at-target="container-children-page-children-list"
							parentContainerId={containerId}
						/>
					</SortableContext>
				</ContainerChildrenLoader>
				{isEventContainer && <EventContainerCover />}
				<NewItemDroppableArea parentId={containerId} shouldShowIndicator={!isEventContainer}>
					{showEmptyMessage && <IllustrationWithText {...illustrationProps} />}
				</NewItemDroppableArea>
			</div>
		</div>
	);

	function getBannerProps() {
		return {
			name: currentContainer?.name || cachedResultFromChildContainer?.name || '',
			isHidden: currentContainer?.isHidden || false,
			isDeletable: currentContainer?.isDeletable,
			onDeleteHandler: parentContainerId
				? generateDeleteHandler({
						id: containerId,
						type: containerCardType || 'container',
						parentId: parentContainerId,
				  })
				: undefined,
		};
	}
}
