import { NEW_CARD_PREFIX } from '@src/components/card/Card';
import {
	ChildContainerFragment,
	ChildContainerFragmentDoc,
	CreateContainerInput,
	useCreateContainerMutation,
} from '@src/graphql/generated';
import { usePendo } from '@src/pendo';
import { useHistoryState } from '@src/shared/hooks';
import { insertToArrayBefore } from '@src/utils/array';

export function useOptimizedCreateContainerMutation() {
	const [mutate] = useCreateContainerMutation();
	const { pendoTrackEvent } = usePendo();
	const { updateHistoryState } = useHistoryState();

	const createContainer = ({
		organizationKey,
		platformCampusKey,
		input,
	}: {
		organizationKey: string;
		platformCampusKey?: string | null;
		input: CreateContainerInput;
	}) => {
		const { applicationId, parentContainerId, name, icon, template, type, insertBefore, relatedContent } = input;
		mutate({
			variables: {
				organizationKey,
				platformCampusKey,
				input: {
					applicationId,
					parentContainerId,
					name,
					icon,
					template,
					type,
					insertBefore,
					relatedContent,
				},
			},
			update(cache, { data }) {
				cache.modify({
					id: cache.identify({
						__typename: 'Container',
						id: parentContainerId,
					}),
					fields: {
						children(existing, { readField }) {
							const createdContainer = data?.createContainer.createdContainer;
							if (!createdContainer) {
								return existing;
							}
							const newChildContainerRef = cache.writeFragment<ChildContainerFragment>({
								data: {
									id: createdContainer.id,
									name: createdContainer.name,
									icon: createdContainer.icon,
									hidden: createdContainer.isHidden,
									position: -1,
									container: {
										id: createdContainer.id,
										type: createdContainer.type,
										campus: createdContainer.campus,
										image: null,
										navigationAction: null,
										isDeletable: true,
										feed: null,
										__typename: 'Container',
									},
									__typename: 'ChildContainer',
								},
								variables: {
									organizationKey,
								},
								fragment: ChildContainerFragmentDoc,
								fragmentName: 'ChildContainer',
							});
							const insertBeforeIndex = existing.nodes
								.map((nodeRef: any) => readField('id', nodeRef))
								.findIndex((id: string) => id === insertBefore);

							const isOptimisticResponse = createdContainer.id.startsWith(NEW_CARD_PREFIX);
							if (!isOptimisticResponse) {
								const isLastPosition = insertBeforeIndex === -1;
								const draggedPosition = isLastPosition ? existing.nodes.length : insertBeforeIndex;

								pendoTrackEvent('Container draggable dropped', {
									draggableType: 'container',
									containerName: createdContainer.name,
									containerType: createdContainer.type,
									draggedPosition,
								});
							}

							return {
								...existing,
								nodes: insertToArrayBefore(existing.nodes, insertBeforeIndex, newChildContainerRef),
							};
						},
						isDeletable() {
							return false;
						},
					},
				});

				if (data?.createContainer.__typename === 'CreateContainerResult') {
					const { createdContainer } = data?.createContainer;

					updateHistoryState({ recentlyCreatedChildId: createdContainer.id });
				}
			},
			optimisticResponse: {
				createContainer: {
					createdContainer: {
						id: NEW_CARD_PREFIX + crypto.randomUUID(),
						isHidden: false,
						isDeletable: true,
						name: input.name,
						icon: input.icon,
						type: input.type,
						campus: null,
						parentContainer: {
							id: parentContainerId,
							navigationAction: null,
							__typename: 'Container',
						},
						__typename: 'Container',
					},
					__typename: 'CreateContainerResult',
				},
			},
		});
	};
	return createContainer;
}
