import { CardProps } from '@src/components/card';
import { DraggableIcon } from '@src/components/draggable';
import { ContainerType } from '@src/graphql/generated';
import { generatePath, ROUTE_PATHS } from '@src/router';
import { validateItemProperties, isExpiredEvent } from '@src/shared/validation';
import { getContainerCardType, getItemCardType } from '@src/utils';

import { ContainerChild, ChildItem } from './types';

export const containerNames: { [key: string]: string } = {
	[ContainerType.Give]: 'Give',
	[ContainerType.Messages]: 'Messages',
};

export const mapChildContainerToCard = (
	childContainer: Extract<ContainerChild, { __typename?: 'ChildContainer' }>,
	parentContainerId: string | undefined,
	parentContainerType: ContainerType,
	applicationId: string,
	canHaveChildren = false
): CardProps => {
	const { id: containerId, name, icon, position, hidden, container } = childContainer;
	const containerType = container?.type;

	return {
		icon: icon ? (icon as DraggableIcon) : 'folder-1',
		name,
		type: getContainerCardType(containerType),
		containerType,
		parentContainerType,
		itemType: undefined,
		position,
		isDeletable: container?.isDeletable,
		isHidden: hidden,
		isMirrored: false,
		isFeedItem: false,
		campus: container?.campus?.name,
		id: containerId,
		isEditable: true,
		rootOnlyChildName: containerNames[containerType] ?? '',
		'data-pp-at-target': 'cards',
		path: generatePath(canHaveChildren ? ROUTE_PATHS.CONTAINER_CHILDREN : ROUTE_PATHS.CONTAINER_SETTINGS, {
			containerId,
		}),
		editPath: generatePath(ROUTE_PATHS.CONTAINER_SETTINGS, { containerId }),
		parentId: parentContainerId,
		hideVisibilityToggle: false,
		applicationId,
		hasInvalidTimeframeProperties: false,
		isExpiredEvent: false,
	};
};

const mapChildItemToCard = (
	applicationId: string,
	parentContainerId: string,
	parentContainerType: ContainerType,
	childItem: ChildItem
): CardProps => {
	const { id: itemId, item } = childItem;
	const { type: itemType, feedId, isMirrored } = item;
	const isFeedItem = Boolean(feedId);

	const { hasInvalidTimeframeProperties, hasNoTimeframeProperty } = validateItemProperties(item);

	const isHidden = hasInvalidTimeframeProperties || hasNoTimeframeProperty || (childItem.hidden && !isFeedItem);

	return {
		icon: childItem.icon ? (childItem.icon as DraggableIcon) : 'file-1',
		name: childItem.name,
		type: getItemCardType(itemType),
		containerType: undefined,
		parentContainerType,
		itemType,
		position: childItem.position,
		isEditable: true,
		isDeletable: true,
		isHidden,
		isMirrored,
		isFeedItem,
		hasInvalidTimeframeProperties,
		hasNoTimeframeProperty,
		campus: undefined,
		id: childItem.id,
		rootOnlyChildName: '',
		'data-pp-at-target': 'cards',
		path: generatePath(ROUTE_PATHS.CONTAINER_ITEM, { containerId: parentContainerId, itemId }),
		editPath: undefined,
		parentId: parentContainerId,
		hideVisibilityToggle: isFeedItem,
		applicationId,
		isExpiredEvent: isExpiredEvent(childItem.item),
	};
};

export function mapContainerChildToCard(
	node: ContainerChild,
	parentContainerId: string | undefined,
	parentContainerType: ContainerType,
	applicationId: string,
	canHaveChildren = false
): CardProps {
	const isChildContainer = node.__typename === 'ChildContainer';
	if (isChildContainer) {
		return mapChildContainerToCard(node, parentContainerId, parentContainerType, applicationId, canHaveChildren);
	}

	const isChildItem = node.__typename === 'ChildItem';
	if (isChildItem && parentContainerId) {
		return mapChildItemToCard(applicationId, parentContainerId, parentContainerType, node);
	}

	throw new Error(`Unknown ContainerChild type ${node.__typename}`);
}
