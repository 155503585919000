import moment from 'moment';

import { set } from '@pushpay/utils';

import { GetContainerChildrenQuery, PropertyType } from '@src/graphql/generated';
import { ContainerChild } from '@src/shared/types';

const getEventStartUnixTime = (child: ContainerChild) => {
	if (child.__typename === 'ChildItem') {
		const timeframeProperty = child.item.properties.find(property => property.type === PropertyType.Timeframe);

		if (timeframeProperty?.__typename === 'TimeframeProperty' && timeframeProperty.timeframe.startTime) {
			return timeframeProperty.timeframe.startTime.unix();
		}
	}

	return moment(0).unix();
};

const sortByName = (a: ContainerChild, b: ContainerChild) => {
	const nameA = a.name.toUpperCase();
	const nameB = b.name.toUpperCase();
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
};
const getSortedChildContainers = (containerChildren: ContainerChild[]) =>
	containerChildren.filter(containerChild => containerChild.__typename === 'ChildContainer').sort(sortByName);

const getSortedChildItems = (containerChildren: ContainerChild[]) =>
	containerChildren
		.filter(containerChild => containerChild.__typename === 'ChildItem')
		.sort((a, b) => getEventStartUnixTime(a) - getEventStartUnixTime(b));

export const sortEventsData = (data: GetContainerChildrenQuery) => {
	const containerChildren = data.organization?.application?.container?.children?.nodes;

	if (!containerChildren) {
		return data;
	}

	const sortedChildContainers = getSortedChildContainers(containerChildren);
	const sortedChildItems = getSortedChildItems(containerChildren);

	return set(data, 'organization.application.container.children.nodes', [
		...sortedChildContainers,
		...sortedChildItems,
	]);
};
