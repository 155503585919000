import { createUseStyles, multiply } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	card: {
		border: `1px solid ${theme.colors['grey-400']}`,
		borderRadius: '6px',
		backgroundColor: theme.colors['background-surface'],
		position: 'relative',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '54px',
		padding: multiply(theme.SPACING.XSMALL, 1.5),
	},
	body: {
		padding: theme.SPACING.SMALL,
		paddingTop: 0,
	},
	divider: {
		marginTop: '1px',
		borderTop: `1px solid ${theme.colors['grey-400']}`,
	},
	title: {
		font: theme.typography['text-4'],
		color: theme.colors['grey-1100'],
		paddingLeft: theme.SPACING.XXSMALL,
		maxWidth: 'calc(100% - 108px)',
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
	},
	dragging: {
		opacity: 0.3,
	},
	dragCursor: {
		cursor: 'grab',
	},
	disabledDragCursor: {
		cursor: 'not-allowed',
	},
	dropAreaBorder: {
		border: 'transparent',
		outline: `2px dashed ${theme.colors['blue-500']}`,
	},
	disabledOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: `${theme.colors['color-background-page']}`,
		pointerEvents: 'auto',
		opacity: 0.5,
		zIndex: 2,
		cursor: 'not-allowed',
	},
}));

export default useStyles;
