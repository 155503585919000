import { useDndContext } from '@dnd-kit/core';

import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {
		zIndex: '1',
		backgroundColor: theme.colors['productBrand-100'],
		opacity: '0.5',
		position: 'absolute',
		top: 0,
		right: `-${theme.SPACING.SMALL}`,
		bottom: 0,
		left: `-${theme.SPACING.SMALL}`,
		border: `1px dashed ${theme.colors['grey-400']} `,
	},

	activeCover: {
		border: `1px dashed ${theme.colors['productBrand-600']} `,
	},
}));

export function EventContainerCover({ children }: any) {
	const classes = useStyles();
	const { active, over } = useDndContext();
	const showCover = typeof active?.id === 'string' && ['add_item_event', 'add_container_events'].includes(active.id);

	return <div className={clsx(showCover && classes.root, over !== null && classes.activeCover)}>{children}</div>;
}
