import { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';

import { Button, SaveButton, SaveButtonProps } from '@pushpay/button';
import { FloatingBar } from '@pushpay/floating-bar';
import { Field, Form, SelectField, TextField } from '@pushpay/forms';
import { Column, Columns, Stack, Tiles } from '@pushpay/layout';

import { AttributePanel } from '@src/components/attributes';
import { ExpandableCard } from '@src/components/expandableCard';
import { SettingsIconField, ImageFieldRef, GivingLinkField, UrlField } from '@src/components/formFields';
import { InteractionBlocker } from '@src/components/interactionBlocker';
import { ConfirmationDialog } from '@src/components/modals';
import { useFormDispatchContext } from '@src/context';
import { ScrollElementIntoViewProvider } from '@src/context/scrollElementIntoViewContext';
import {
	ContainerType,
	GetContainerSettingsQuery,
	NavigationAction,
	useSaveContainerSettingsForm,
} from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';
import { useMyApp } from '@src/myContext';
import { useGetQueryAndMutationVars } from '@src/shared/hooks';
import { useEvent } from '@src/utils';

import { ContainerCardDefinition } from './components/ContainerCardDefinition';
import { ContainerImageField } from './components/ContainerImageField';
import { ContainerLayoutSettings } from './components/ContainerLayoutSettings';
import { ContainerTypeField } from './components/ContainerTypeField';
import { RelatedContentSetting } from './components/RelatedContentSetting';
import { SubtitleField } from './components/SubtitleField';
import { useConfirmModal } from './components/useConfirmModal';
import { useStyles } from './containerSettingFormStyle';
import {
	generateContainerSettingsFormInitialValues,
	generateContainerSettingsFormFieldVisibility,
} from './utils/containerSettingFormInitialDataGenerator';
import { getFieldsEnablement } from './utils/getFieldsEnablement';

type ContainerSettingsFormProps = {
	refetchContainerSettings?: () => void;
	containerSettings: NonNullable<NonNullable<GetContainerSettingsQuery['organization']>['application']>['container'];
	isDHSEnabled: boolean;
};

export type ContainerSettingFormRefType = {
	onConfirmDelete: () => void;
	resetBeforeDelete: () => void;
};

const useActionOptions = () => {
	const { translate } = useTranslation('appDesign');
	const actionOptions = [
		{ display: translate('settings.container.titlebarOptions.noAction'), value: NavigationAction.Unknown },
		{ display: translate('settings.container.titlebarOptions.filter'), value: NavigationAction.Filter },
		{ display: translate('settings.container.titlebarOptions.map'), value: NavigationAction.Map },
		{ display: translate('settings.container.titlebarOptions.search'), value: NavigationAction.Search },
	];

	return actionOptions;
};
export const ContainerSettingsForm = forwardRef<ContainerSettingFormRefType, ContainerSettingsFormProps>(
	({ containerSettings, isDHSEnabled, refetchContainerSettings }, ref) => {
		const { useSetForm } = useFormDispatchContext();
		const { organizationKey, platformCampusKey, applicationId } = useGetQueryAndMutationVars();
		const { translate } = useTranslation('appDesign');
		const { currentApp } = useMyApp();

		const containerSettingsFromServer = useRef(containerSettings);
		// not update the initial container settings when the container settings is updated by apollo client.
		const [updateInitialContainerSettings, setUpdateInitialContainerSettings] = useState(true);
		if (updateInitialContainerSettings) {
			containerSettingsFromServer.current = containerSettings;
		}
		const containerSettingsFormVisibility = {
			input: generateContainerSettingsFormFieldVisibility(containerSettingsFromServer.current),
		};
		const { formContext, formState, dirty, reset, submit, saving, successResult, graphQLError } =
			useSaveContainerSettingsForm({
				initialData: {
					organizationKey,
					platformCampusKey,
					input: generateContainerSettingsFormInitialValues(
						containerSettingsFromServer.current,
						applicationId
					),
				},
				fieldVisibility: containerSettingsFormVisibility,
				mutationOptions: {
					update(cache, { data }) {
						if (data?.saveContainerSettings?.updatedContainer) {
							const { id, name, icon } = data.saveContainerSettings.updatedContainer;
							cache.modify({
								id: cache.identify({
									id,
									__typename: 'ChildContainer',
								}),
								fields: {
									name: () => name,
									icon: () => icon,
								},
							});
						}
					},
				},
			});

		useSetForm({ formState });

		const readOnlyForm = [ContainerType.All, ContainerType.PushCategory, ContainerType.Favorites].includes(
			containerSettings?.type ?? ContainerType.Unknown
		);

		const classes = useStyles(undefined);
		const imageFieldRef = useRef<ImageFieldRef>(null);
		const [isUploading, setIsUploading] = useState(false);
		const [isConfirmModalOpen, setIsConfirmModalOpen, onProceed, onModalReset, onConfirmDelete] =
			useConfirmModal(dirty);
		const [isDiscarding, setIsDiscarding] = useState(false);
		const [hasMutationFailed, setHasMutationFailed] = useState(false);

		const hasNetworkError = !!graphQLError;

		const onNetworkError = useEvent(() => {
			if (isConfirmModalOpen) {
				setHasMutationFailed(true);
			}
		});

		useEffect(() => {
			if (hasNetworkError) {
				onNetworkError();
			}
		}, [hasNetworkError, onNetworkError]);

		useEffect(() => {
			if (isConfirmModalOpen && hasMutationFailed) {
				setHasMutationFailed(false);
				setIsConfirmModalOpen(false);
				onModalReset();
			}
		}, [isConfirmModalOpen, hasMutationFailed, setIsConfirmModalOpen, onModalReset]);

		useEffect(() => {
			if (!successResult) {
				setUpdateInitialContainerSettings(true);
			}
		}, [successResult]);

		const titleBarOptions = useActionOptions();

		const onReset = () => {
			reset();
			onProceed();
		};

		useImperativeHandle(
			ref,
			() => ({
				onConfirmDelete,
				resetBeforeDelete: reset,
			}),
			[onConfirmDelete, reset]
		);

		if (!containerSettings) {
			return null;
		}

		const onSubmit = async () => {
			setIsUploading(true);
			const uploadSuccess = (await imageFieldRef.current?.uploadFile()) ?? true;
			setIsUploading(false);
			if (uploadSuccess) {
				submit();
				// update the initial form visibility
				setUpdateInitialContainerSettings(true);
			}
		};

		const onDiscard = () => {
			setIsConfirmModalOpen(true);
			setIsDiscarding(true);
		};

		const onConfirmDiscard = () => {
			refetchContainerSettings?.();
			setUpdateInitialContainerSettings(true);
			imageFieldRef.current?.resetField();
			onReset();
		};

		const onContinueEditing = () => {
			setIsConfirmModalOpen(false);
			setIsDiscarding(false);
			onModalReset();
		};

		const fieldsEnablement = getFieldsEnablement(containerSettings.type, containerSettings.children.nodes);
		const saveButtonState = getSaveButtonState();

		const subtitleField = fieldsEnablement?.isSubtitleEnabled && !!formState.input.template.value && (
			<SubtitleField
				key="subtitleField"
				classes={{
					label: classes.label,
				}}
				container={containerSettings}
				field={formState.input.subtitle}
				layoutTemplate={formState.input.template.value}
				readonly={readOnlyForm}
			/>
		);
		const conditionalFields = [
			fieldsEnablement?.isTitleBarDropdownDisplayed && (
				<SelectField
					key="titleBarField"
					classes={{ label: classes.label }}
					defaultValue={NavigationAction.Unknown}
					field={formState.input.navigationAction}
					label={translate('settings.container.titlebar')}
					labelPosition="top"
					options={titleBarOptions}
					readOnly={readOnlyForm}
					showLabel
				/>
			),
			fieldsEnablement?.isTitleBarTextDisplayed && (
				<TextField
					classes={{
						label: classes.label,
						wrapper: classes.textFieldWrapper,
					}}
					field={formState.input.navigationAction as Field<NavigationAction>}
					label={translate('settings.container.titlebar')}
					labelPosition="top"
					textInputProps={{
						value:
							titleBarOptions.find(option => option.value === formState.input.navigationAction.value)
								?.display ?? NavigationAction.Unknown,
					}}
					tooltip={
						containerSettings.type === ContainerType.Events
							? translate('settings.container.disabledEventTitleBarTooltip')
							: undefined
					}
					readOnly
					showLabel
				/>
			),
			fieldsEnablement?.isGivingLinkEnabled && (
				<GivingLinkField
					key="givingLinkField"
					applicationGivingLink={currentApp?.givingLink}
					campusGivingLink={containerSettings.campus?.givingLink}
					classes={{
						label: classes.label,
					}}
					field={formState.input.givingLink}
				/>
			),
			!fieldsEnablement?.isLayoutOptionsEnabled && subtitleField,
		].filter(Boolean);

		const saveButton = (
			<SaveButton
				idleStateButtonText={translate('actions.saveButton.text.idle')}
				savingStateButtonText={translate('actions.saveButton.text.saving')}
				state={saveButtonState}
				successStateButtonText={translate('actions.saveButton.text.success')}
				onSave={onSubmit}
				onSuccess={onReset}
			/>
		);

		const discardButton = (
			<Button displayStyle="primary" type="button" onClick={onConfirmDiscard}>
				{translate('actions.discard.text.idle')}
			</Button>
		);

		const getConfirmationDialogOptions = () => {
			if (isDiscarding) {
				return {
					title: translate('discardDialog.modalTitle'),
					bodyText: translate('discardDialog.modalText'),
					cancelButtonHandler: onContinueEditing,
					cancelButtonLabel: translate('discardDialog.cancel'),
					confirmButton: discardButton,
				};
			}

			return {
				title: translate('confirmationDialog.modalTitle'),
				bodyText: translate('confirmationDialog.modalText'),
				cancelButtonHandler: isConfirmModalOpen ? onConfirmDiscard : onDiscard,
				cancelButtonLabel: translate('confirmationDialog.discard'),
				confirmButton: saveButton,
			};
		};

		const confirmationDialogOptions = getConfirmationDialogOptions();

		return (
			<>
				<Form className={classes.form} formContext={formContext}>
					<ExpandableCard
						classes={{
							body: classes.expandableCardBody,
							title: classes.expandableCardTitle,
						}}
						initialExpandStatus="preexpanded"
						title={translate('settings.title')}
					>
						<Stack
							classes={{
								root: classes.generalStack,
								stackItem: classes.stackItem,
							}}
						>
							<Columns>
								<Column width="1/2">
									<TextField
										classes={{
											label: classes.label,
											wrapper: classes.textFieldWrapper,
										}}
										field={formState.input.name}
										label={translate('settings.container.name')}
										labelPosition="top"
										readOnly={readOnlyForm}
										showLabel
									/>
								</Column>
								<Column width="1/4">
									<ContainerTypeField
										classes={{
											label: classes.label,
											wrapper: classes.textFieldWrapper,
										}}
										field={formState.input.type}
									/>
								</Column>
								<Column width="1/4">
									<SettingsIconField
										classes={{
											label: classes.label,
										}}
										field={formState.input.icon}
										readOnly={readOnlyForm}
										template={containerSettings?.parentContainer?.template}
									/>
								</Column>
							</Columns>
							{conditionalFields.length > 0 && (
								<Tiles classes={{ tile: classes.tile }} columns={3}>
									{conditionalFields}
								</Tiles>
							)}
							{fieldsEnablement?.isLayoutOptionsEnabled && (
								<Tiles columns={1}>
									<ContainerLayoutSettings
										container={containerSettings}
										readonly={readOnlyForm}
										templateField={formState.input.template}
									/>
								</Tiles>
							)}
							{fieldsEnablement?.isLayoutOptionsEnabled && subtitleField && (
								<Tiles classes={{ tile: classes.tile, root: classes.subTitleRoot }} columns={3}>
									{subtitleField}
								</Tiles>
							)}
							{fieldsEnablement?.isResiFeedEnabled && (
								<Tiles classes={{ tile: classes.tile }} columns={1}>
									<UrlField
										classes={{ label: classes.label }}
										field={formState.input.resiLink}
										label={translate('settings.container.resiFeed')}
										labelPosition="top"
										showLabel
									/>
								</Tiles>
							)}
						</Stack>
						{fieldsEnablement?.isImageEnabled && (
							<div className={classes.section}>
								<ContainerImageField
									containerId={containerSettings.id}
									field={formState.input.imageUrl}
									fieldLabel={
										containerSettings?.type === ContainerType.Resi
											? translate('imageUploader.heroBannerLabel')
											: undefined
									}
									image={containerSettings.image}
									imageFieldRef={imageFieldRef}
									readOnly={readOnlyForm}
								/>
							</div>
						)}
						{fieldsEnablement?.isDHSOptionsEnabled && isDHSEnabled && (
							<ContainerCardDefinition
								classes={{ root: classes.section }}
								containerSettings={containerSettings}
								fields={formState.input.cardDefinition}
							/>
						)}
						{fieldsEnablement?.isRelatedContentOptionsEnabled && (
							<RelatedContentSetting
								classes={{ root: classes.section }}
								field={formState.input.relatedContent}
							/>
						)}
					</ExpandableCard>
					{fieldsEnablement?.isAttributeEnabled && (
						<div className={classes.section}>
							<ScrollElementIntoViewProvider>
								<AttributePanel
									attributeFields={formState.input}
									container={containerSettings}
									isContainerCachePolluted={!updateInitialContainerSettings}
									onCacheUpdate={() => setUpdateInitialContainerSettings(false)}
								/>
							</ScrollElementIntoViewProvider>
						</div>
					)}
					<ConfirmationDialog
						bodyText={confirmationDialogOptions.bodyText}
						cancelButtonHandler={confirmationDialogOptions.cancelButtonHandler}
						cancelButtonLabel={confirmationDialogOptions.cancelButtonLabel}
						closeButtonHandler={onContinueEditing}
						confirmButton={confirmationDialogOptions.confirmButton}
						isConfirmModalOpen={isConfirmModalOpen}
						setIsConfirmModalOpen={setIsConfirmModalOpen}
						title={confirmationDialogOptions.title}
					/>
					<InteractionBlocker isActive={saveButtonState !== 'idle'} />
				</Form>

				<FloatingBar
					className={classes.floatingBar}
					data-pp-at-target="FloatingBar-id"
					sticky={false}
					visible={!!successResult || dirty || saving}
				>
					<Button
						disabled={saveButtonState !== 'idle'}
						displaySize="large"
						displayStyle="text"
						type="button"
						onClick={onDiscard}
					>
						{translate('actions.discard.text.idle')}
					</Button>
					{saveButton}
				</FloatingBar>
			</>
		);

		function getSaveButtonState() {
			let status: SaveButtonProps['state'] = 'idle';
			if (saving || isUploading) {
				status = 'saving';
			} else if (successResult) {
				status = 'success';
			}
			return status;
		}
	}
);
